import React from "react";
import { Link } from "react-router-dom";
import { discover, playstore, appstore } from "../assets";

import { webEvents, webEventsCategory } from "../constants";
import { trackEvent } from "../eventDispatchers/MixpanelEventDispatcher";
import { dispatchReactGAEvent } from "../eventDispatchers/GoogleAnalyticsEventDispatcher";

const Intro = () => {
  return (
    <div className="comp2 w-full bg-white text-[#000000] py-24">
      <div className="md:max-w-[1215px] m-auto grid md:grid-cols-2 max-w-[600px] px-4 gap-20 md:px-0">
        <div className="flex flex-col justify-center gap-4">
          <h1 className="md:leading-[72px] py-2 md:text-6xl text-5xl font-heading font-medium">
            Expensive airport <span className="text-primary">Cab</span> burning
            your pocket?
          </h1>
          <p className="py-2 text-2xl text-[#000000] font-medium">
            Booking a cab from airport is too expensive? <br /> Why not find a
            co-traveler, and save money?
          </p>
          <p className="py-2 text-xl text-[#000000] font-medium">
            A platform meant for Bangalore airport where you can find someone
            who is traveling to the same location. You meet them, travel
            together, and split the fare
          </p>
          <div className="flex justify-start py-6 md:gap-8 ">
            <div className="grid md:grid-cols-2 grid-cols-2 gap-2">
              <Link
                to="https://play.google.com/store/apps/details?id=com.cherryco.gopool.android"
                target="_blank"
                onClick={() => {
                  dispatchReactGAEvent({
                    category: webEventsCategory.USER,
                    action: webEvents.PLAYSTORE_BUTTON_CLICKED,
                  });
                  trackEvent(webEvents.PLAYSTORE_BUTTON_CLICKED, {});
                }}
              >
                <img
                  className="h-20 hover:scale-105 duration-300"
                  src={playstore}
                  alt="logo"
                />
              </Link>
              <Link
                to="https://apps.apple.com/us/app/go-pool/id1661427392"
                target="_blank"
                onClick={() => {
                  dispatchReactGAEvent({
                    category: webEventsCategory.USER,
                    action: webEvents.APPSTORE_BUTTON_CLICKED,
                  });
                  trackEvent(webEvents.APPSTORE_BUTTON_CLICKED, {});
                }}
              >
                <img
                  className="h-20 hover:scale-105 duration-300"
                  src={appstore}
                  alt="logo"
                />
              </Link>
            </div>
          </div>
        </div>

        <div className=" ml-20 relative flex">
          <img
            src={discover}
            alt="discover"
            className=" rounded-[2.3rem] top-[4%] left-[40%] w-[60%] limg "
          />
        </div>
      </div>
    </div>
  );
};

export default Intro;
